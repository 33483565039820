html {
  font-size: 62.5%; /* 16px * 62.5 = 10px = 1rem */
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  background-color:#f0ad4e;



}
.app{
  background-color:#f0ad4e;
}

*, *:before, *:after {
  box-sizing: inherit;
}

/*GLOBALES*/
/**FONTS***/

@import url('https://fonts.googleapis.com/css2? family = Roboto: ital @ 1 & display = swap');


  #root {
    height: 100%;
    background-color: #f0ad4e
  }

  body {
    background-color:#f0ad4e;
    text-align: center;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    font-display: swap;
    /* margin-bottom: 40px; */
    }


    /*TABS*/
    .tabs-container{
      display: flex;
      justify-content: center;
    }
    .rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {

      border-bottom: 3px solid orange !important;
  }
  .hr{
    border: 0;
    height: 0;
    border-top: 1px solid #1a1919;
  }

  
  
    /****titulos y textos*****/
    .bold{
      font-weight: bold;
    }
    .texto-general{
      display:inline-block;
    padding: 1rem;
    text-align: initial;
    line-height: 2.5rem;
    margin-left: 1rem !important;
    
    }
    .color-black{
      color: black;
    }
    .color-white{
      color: white;
    }
    .color-amarillo{
      color: #FFFF77;
    }
    .centrar{
    display: flex;
    align-items: center;
    justify-content: center;
    }
    p{
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    h1{
    text-align: initial;
     color:  #FFFF77;
     font-family: 'Roboto', sans-serif;
     font-display: swap;
     font-weight: bold;
     margin-left: 1rem;
     margin-bottom: 1rem !important;
     margin-top: 1.5rem !important;

    }
    h2{
      text-align: initial;
      color: white;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      /* font-size: 1.5rem !important; */
      font-size:1.8rem !important;
      margin-left: 1rem;
      font-weight: bold !important;
    }
    .p{
      color: white;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      font-size: 1.5rem !important;
      margin-left: 1rem;
    }
    .p2{
      color: black;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      font-size: 1.5rem !important;
      margin-left: 1rem;
    }
    .link-naranja{
      color: orange;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      font-size: 1.5rem !important;
      margin-left: 1rem;
      margin-bottom: 0.7rem;
      font-weight: bold !important;
    }

    /***TERMINOS Y CONDICIONES***/
    .datos-a{
      padding: 1.5rem;
    }

    /***BOTONES,SELECT,INPUT****/
    .search{
      display: flex;
      background-color: whitesmoke;
      border-radius: 1rem;
      height: 3.5rem;
      justify-content: center;
      align-items: center;
      padding-left: 1rem;
    }
    .filter {
      display: flex;
      list-style-type: none;
      padding: 0;
      justify-content: space-around;
      align-items: center;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      margin-right: 1rem;
      margin-left: 1rem;
     
    }
    @media (min-width:760px) {
      .filter {
    display: flex;
    list-style-type: none;
    justify-content: left;
    padding: 0;
    align-items: center;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
}
.filter select{

  width: 15rem !important;
 
}
.search{
  margin-left: 3rem;
  width: 25rem !important;
}

    }


    .filter-todo{
      font-size: calc(16px + 6 * ((10vw - 320px) / 680));
      background-color: #6d3438;
      border: rgb(196, 184, 184);
      border-radius: 1rem;
      color: white;
      width: 8rem;
      height: 3.5rem;
      margin: 2rem;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      font-weight: bold;

    }
    .filter-1 {
      display: flex;
      list-style-type: none;
      width: 15rem;
      height: 3rem;
      justify-content: space-between;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      
     
    }
    .filter input,
.filter button {
  
  border-radius: 1rem;
  border: 0.1rem white;
  font-size: 1.6rem;
  background-color:whitesmoke;
  height: 3.5rem;
  width:15rem;
  font-family: 'Roboto', sans-serif;
  font-display: swap;
}
    .filter select {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      border: 0.1rem #6d3438;
      font-size: 1.6rem;
      background-color: #6d3438;
      color:white;
      font-weight: bold;
      height: 3.5rem;
      width:15rem;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    .filter option{
      background-color: whitesmoke;
      color: black;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    .sesion{
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 1rem;
      font-size: calc(16px + 6 * ((4vw - 320px) / 680));
      background-color:#FFD800;
      border: #FFD800;
      border-radius: 1rem;
      color: black;
      cursor: pointer;
      width:8rem;
      height: 3.8rem;
      margin: 0 auto;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      font-weight: bold;
     
    }
    .botones{
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 2rem;
    }
    .centrar-botones{
      display: flex;
      flex-direction: column;

    }
    .botones-img{
      display: flex;
      flex-direction: row;
      justify-content: center;

    }
    .input {
      width: 32rem;
      height: 4rem;
      padding: 20px;
      margin-left:0.5rem;
      border: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      font-size: 1.5rem;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    ::placeholder{
      font-size: 1.5rem;
    }
    .input-cuenta{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30rem;
      height: 4rem;
      padding: 20px;
      margin-left: 0.5rem;
      border: #fff;
      border-radius: 10px;
      font-size: 1.5rem;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    
    }
    .input-pago{
      border: #fff;
      width: 8rem;
      font-size: 1.5rem;
      
    }
    .input-cuenta-doc{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 10rem;
      height: 4rem;
      padding: 20px;
      margin-left: 0.5rem;
      border: #fff;
      border-radius: 10px;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    .input-cuenta2{
      width: 10rem !important;
      margin-left: 2rem;
      margin-bottom: 2rem; 
      border: none;
    }
    .input-cuenta3{
      width: 13rem !important;
      margin-left: 2rem;
      margin-top: 1rem;
      border: 1px solid #ced4da !important;
      
    }
    .container-f{
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
    }
    .container-f2{
      display: flex;
      justify-content:center;
      align-items: center;
      flex-direction: column;
      margin-left: 0!important;
    }
    
    /* .columnas{
      margin-left: 10rem;
    }  */
    @media (min-width:860px){
      .container-f{
        display: flex;
        flex-direction: row;
        align-items: baseline;
      
    }
    .container-f2{
      display: flex;
      flex-direction: row;
      align-items: baseline;
      margin-left: 0!important;
    
  }


    .input {
      width: 40rem;
      height: 4rem;
      padding: 20px;
      margin-left:0.5rem;
      border: #fff;
      border-radius: 10px;
      margin-bottom: 20px;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }


    }

    .label {
      display: flex;
      font-size: 1.5rem !important;
      color: white;
      justify-content: right;
      padding-left: 10px;
      padding-right: 20rem;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    .label1{
      display: flex;
      font-size: 1.5rem !important;
      color: black;
      justify-content: right;
      padding-left: 10px;
      font-family: 'Roboto', sans-serif;
      font-display: swap;
    }
    .comentarios{
      padding: 25px 25px 25px 25px;
      border: white;
      border-radius: 20px;
      margin-bottom: 20px;
      width: 32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 1rem;
  
    }
    .button-red{
      font-size: calc(16px + 6 * ((10vw - 320px) / 680));
  background-color: #6d3438;
  border: rgb(196, 184, 184);
  border-radius: 1rem;
  color: white;
  width: 13rem;
  height: 3.5rem;
  margin: 2rem;
  font-family: 'Roboto', sans-serif;
  font-display: swap;
  font-weight: bold;


    }
    /***main-container****/
    .main-container{
      min-height: 100vh;
    }
    @media (min-width: 1900px){
      .main-container {
        max-width: 1508px;
        margin: 0 auto 8px;
        padding: 16px 16px 0 16px;
    }
  }


  /****FOOTER****/
  .footer{
    background-color:  #f0ad4e;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    color: white;
  }
  .logo-footer{
 
    width: 16rem;
    height: 5rem;

  }
  .footer-logo{
    display: flex;
    justify-content: center;
    margin: 2rem;
   

  }
  .footer-derechos{
    display: flex;
    justify-content: center;
  }
  .footer-redes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  .redes-sociales-f{
    margin-right: 3rem;
    height: 2rem;

  }
  .redes-sociales-f-w{
    margin-right: 1rem;
    height: 2rem;

  }

  .separador{
    font-size: 3.5rem;
    margin-right: 2rem;
    margin-bottom: 1rem;
  }
  .whatsapp-c{
    display: flex;
    align-items: center;

  }
  .a-c{
    margin-top: 1rem;

  }
  /******NAVIGATION******/
  .navigation{
    background-color: #f09f2f;
  
  }
  .icono-cart{
    display: flex;
    justify-content: center;
    background: none;
    border: none;
    margin-bottom: 1rem;

    
    
  }
  .cart-header{
    background-color: grey;
    color: white;
    border-radius: 100%;
    padding-right: 0.4rem;
    padding-left: 0.4rem;
    margin: 16px;
  }
  .MuiPopover-paper {
    opacity: 1;
    transform: none;
    max-height: 216px;
    width: 200ch !important;
    transition: opacity 287ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 191ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    top: 80px !important ;
    /* max-width: calc(100% - 20px) !important; */

   
    /* left: 24px !important; */
    transform-origin: 71.9844px 31.5px;
  }

  @media (min-width:700px){
    .MuiPopover-paper {
      width: 120ch !important;
      /* max-width: calc(100% - 32px) !important; */
    }
  }
  
  .icon-logo{
    height: 3.5rem;
    width: 11rem;
   
  }
  .boton-user{
    height: 3.5rem;
    width: 4rem;
    margin-left: 1rem;


  }
  .user-m{
    z-index: 1 !important;
    position:fixed !important;
  }
  .MuiIconButton-root {
    padding: 1px !important;
  }
  .MenuItem{
    height: 1rem;
  }
  .MuiMenuItem-root {
    min-height: 35px !important;
  }
  .MuiListItem-button:hover {
    text-decoration: none;
    background-color: white !important;
}
  .carrito-1{
    width: auto;
    height: 3.2rem;
    border: none;
    background:none; 
  }
  .icono{
    height: 3.6rem;
    width: 3.6rem;
    
    background:none;
  
  }
  .icono-button{
    background:none;
  }
  .MuiIconButton-root{
    border-radius: 0% !important;
  }
  .contenedor-nav{
    display: flex;
    margin: auto;
    width: 98%;
    justify-content: space-between;
    align-items: center;
    height:inherit;
    overflow: hidden;
    z-index:170;
margin:0 auto;
background-color: #f09f2f;
width:100%;
position:fixed;
top:0;
left:0;
right:0;
text-align: center;
box-shadow: 0 0 15px 0 rgb(0 0 0 / 50%);
  }
    
  nav{
    width: 100%;
    background-color: #f09f2f;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.5)
  }
  
  .nav1{
    background: transparent;
    height: 80px;
    color:#fff;
    



  }
  .nav2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
  }
  .logo-nav{
    display:flex;
    justify-content: center;
    justify-items: center;



  }
  .logo-nav-1{
    display: flex;
    justify-content: center;
    background: none;
    border: none;

  }
  .contenedor-nav-sidebar-22 {
    display: flex;
    /* margin: auto; */
    width: 58%;
    justify-content: space-between;
    align-items: center;
    height: inherit;
    overflow: hidden;
}

  .logo-nav-2{
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  @media (min-width:799px){
    .nav1{
      display: none;
    }
  }
  @media (max-width:800px){
    .nav2{
      display: none;
    }
  
  }
  .lista-navbar{
    display: contents;
    margin: auto;
  }
  .lista-sidebar-n{
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-display: swap;
    

  }
  .lista-sidebar-ns {
    display: -webkit-box;
    justify-content: space-between;
    justify-items: center;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    font-display: swap;
}
    /* Sidebar */

      
    
    .sidebar-close-button {
      font-size: 4rem;
      padding: 0.5rem;
      background: none;
      border: none;
      color: #ffffff;
      cursor: pointer;
      display: block;  
    }
    .close {
      position: absolute;
      right: 10px;
      top: 8px;
      width: 32px;
      height: 32px;
      background: none;
      border: none;
  
    }
  
    .close:before, .close:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 30px;
      width: 2px;
      background-color: white;
    }
    .close:before {
      transform: rotate(45deg);
      background-color: white;
    }
    .close:after {
      transform: rotate(-45deg);
      background-color: white;
    }
    
  
    .sidebar {
      position: fixed;
      top: -1.6px;
      background-color: #f09f2f;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
      border: solid 1px;
      border-radius: 10px 10px 10px 10px;
      left: 0;
      height: 80%;
      transition: 1s;
      overflow: hidden;
      transition: all -1s;
      transform: translateX(-40rem);
      width: 30rem;
      z-index: 170;
     
  
  
    }
    


      
    
    .sidebar.open {
      transform: translateX(0);
    
    }  


    .lista-sidebar{

      display: -webkit-box;
      justify-content: space-between;
      justify-items: center;
      margin-left: 2.5rem;
      height: 3rem;
      margin-bottom: 1rem;
      font-size: 2rem;
      color: white;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
      font-display: swap;

  }
  .lista{
    margin-top: 2rem;
  }

  a:active{
    color:orange !important;
  }
  a:hover {
    color:orange !important;
  }
  /**CARRITO Y CART HOME****/
  .cart-cart-header{
    border-bottom: 0.1rem #c0c0c0 solid;
    display: flex;
    justify-content: center;
    color: white;
    margin-top: 4rem;
  }
  .titulo-cart{
    width: 10rem;
  }
  .cart {
    padding: 1rem;
    margin: 1rem;
    display: flex;
    color: #fff;
  
  }

  .cart-home{
    display: flex !important;
    flex-direction:column !important
  }
  .cart-home2 {
    display: flex !important;
    flex-direction: column !important;
    margin: 2rem;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
}
.placeorder-action{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.placeorder-action13{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
}
.boton-canjear-container{
  display: flex;
    justify-content: center;
   

}
  .cart-items {
    display: flex;
    flex-direction: row !important;
    width: 100% !important;
    list-style-type: none !important;
  
  }
  .home-img-cart{
    display: flex;
    padding: 1rem;
    background-color: white;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

  }
  .imagen-carrito{

    width: 5em;
    height: 5em;
    }
    .tachito{
    max-width: 2rem;
    max-height: 2rem;
    margin-top: 2rem;
}
    
    .qty{
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgb(224, 220, 220);
      border-radius: 2rem;
      border: black;
      height: 2.5rem;
      margin-top: 3rem
  }
    .show-qty{
      width: 1rem;
    }
 
    .cart-button{
      background: none;
      border: none;
      
    
    }
    .cart-button-bonos{
      background: none;
      border: none; 
    
    }
    .canjear-b{
      display: flex;
    flex-direction: row-reverse;

    }
    .cart-price-tachito{
      text-align: center;
      margin-top: 1rem;
    }
    .parrafo-carrito{
      width: 10rem;
      margin-left: 1rem;
      margin-top: 1rem;
    }
    .parrafo-carrito22{
      width: 10rem;
      margin-left: 1rem;
      margin-top: 1rem;
      margin-right: 3rem;
    }
    .product-price-details{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
    .container-cart-19{
      display:flex;
      justify-content:center;
      align-items:center;
      flex-direction:column;
    }
    @media (min-width:700px){
      .parrafo-carrito{
        width: 18rem;
        margin-left: 10rem;

      }
      .parrafo-carrito22{
        width: 18rem;
        margin-left: 10rem;

      }
      .cart-price-tachito{
        margin-left: 10rem;
      }
      .home-img-cart{
      margin-left: 3rem;
      }
    }




/****HOME****/
.imagen-home{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background-color: #FFBA00;
  padding: 2rem;
 
}
.imagen-home-juego{
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  margin: 3rem;
  padding: 2rem;

}
.juego-dados{
  max-width: 13em;
  max-height: 13em;
}
@media (min-width:700px){
  .juego-dados{
    max-width: 35em;
    max-height: 35em;
  
  } 
}
.imagen-home-banderas{
  background-color:#f0ad4e;
  display: flex;
  justify-content: center;
}
.banderas-img{
  max-width: 100rem;
  max-height: 100rem;
  min-width: 12rem;
  min-height: 12rem;
}
.imagen-home-promociones{
  background-color: #f09f2f;
  padding-top: 0.1rem;


}
.imagen-home-promociones1-oculto{
  display: none;
}

.imagen-home-tutoriales {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  background: rgb(225 122 20);
  padding: 2rem;
  align-items: center;

}
.col-derecha{
  display: flex;
  justify-content: center;
  flex-direction: column;

}
.col-derecha-d{
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 4.5rem

}
.juego-t-img{
  width: 100%;
  height: auto;
}
.juego-t-img-mbokaja {
  width: 17rem;
  height: 9rem;
}
.juego-piña{
  max-width: 17em;
  max-height: 17em;


}
@media (min-width:700px){
  .juego-piña{
    max-width: 30em;
    max-height: 30em;
  
  }
  .juego-t-img-mbokaja {
    width: 25rem;
    height: 12rem;
  } 
}
.header-home{
  background: rgb(225 122 20);
  width: 100%;

}
.header-img{
  width: inherit;
  height: 10em;
}
.header-img-cel{
  width: 100%;
  height: 10em;
}
.header-img-compu{
  display: block;
  margin: 0 auto;
  max-width: 100%;
}


@media (min-width:466px){

  .header-img-cel{
    display: none;

  }
}
@media (max-width:467px){
  .header-img-compu{
    display: none;
    margin: 0 auto;
    max-width: 100%;
    width: 80%;
  }

}
/******/
.bandera-home{
  background: #450F1D;
  width: 100%;

}
.bandera-img-cel{
  width: 100%;
  height: 15em;
}
.bandera-img-compu{
  width: 100%;
    max-height: 45em;
}


@media (min-width:466px){

  .bandera-img-cel{
    display: none;

  }
}
@media (max-width:467px){
  .bandera-img-compu{
    display: none;
    margin: 0 auto;
    max-width: 100%;
    width: 80%;
  }

}
/* .nuestro-juegos{
  height: 32rem;
} */
.slick-slide img{
  justify-content: center;

}
.img-card{
    width: 100%;
    display: flow-root;
    justify-content: center;
    align-items: center;
}
  

/***PRODUCTOS**/
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  width: 100%;
  border-radius: 3rem !important;

}
.botones-productos{
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column
}
.slick-slider {

  background-color: transparent !important;
  border:none !important;


}
.descripcion-p{
  width: 19rem;
  max-height: 15rem;
}
.nivel-user{
  margin-right: 0.5rem;
}

.card-container{
  display: flex;
  justify-content: center;
  align-items: center;

}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.container {
  padding: 2px 16px;
}
.img-card {
  max-width: 15rem;
}
.products-carousel{
  max-width: 15rem;
  height: 15rem;

}


  .card-1 {
    width: 18rem;
    height: 47rem;
}
.card-title{
  margin-top:1rem;
  margin-bottom:1rem;
  height:5rem
}

.card-2{
  margin-left: 2rem;
  }

.puntos-22{
  display: block;
    align-items: baseline;
    text-align: initial;

}
@media (min-width:700px){
/* .card-2{
margin-left: 8rem;
} */

}
@media (min-width:700px){
  .card-1 {
    width: 23rem;
    height: 47rem;
  }
  }
@media (min-width:900px){
  .card-1 {
    width: 30rem;
    height: 47rem;
  }
  }
  @media (min-width:999px){
    .card-1 {
      width: 30rem;
      height: 47rem;
    }
    }
    @media (min-width:1000px){
      .card-1 {
        width: 26rem;
        height: 47rem;
      }
      }
      @media (min-width:1300px){
        .card-1 {
          width: 30rem;
          height: 47rem;
        }
        }
        @media (min-width:1800px){
          .card-1 {
            width: 36.5rem;
            height: 47rem;
          }
          }

/*Juegos home*/
.juegos-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
}
.juegos-fila{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 1rem;

}
.juegos-img{
  width: 16em;
  height: 16em;
}

.texto-fila{
  height: 30rem;

}
.titulo-juegos{
  height: 6rem;
}


@media (min-width:950px){
  .juegos-texto-12{
    padding: 2rem;
    padding-left: 20rem;
    padding-right: 20rem;
    }
  }
/***PROMOCIONES HOME**/

.img-juego{
  max-width: 30rem !important;
  height: 30rem;
}
.img-juego-2{
  
  max-width: 100% !important;
  max-height: 30rem;
  min-width: 35rem;
  min-height: 15rem;
    }
    .img-juego-3{
      
      max-width: 90% !important;
      max-height: 30rem;
      min-width: 36rem;
      min-height: 15rem
    }


@media (min-width:700px){
  .img-juego-2{
display: none !important;
max-width: 100% !important;
max-height: 30rem;
min-width: 36rem;
min-height: 15rem;
  }
}
@media (max-width:701px){
  .img-juego-3{
    display: none !important;
    max-width: 100% !important;
    max-height: 30rem;
    min-width: 36rem;
    min-height: 15rem
  }

}


.promociones-img {
  width: 16em;
  height: 16em;
  margin: 1rem;
}


@media (min-width:700px){
.promociones-img{
        width: 30rem !important;
        height: 30rem;
        margin-left: 2rem;
      }
}
.promociones-container{
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-bottom: 40rem;

}
.card-h{
  margin-right: 2rem;
}

/*****TUTORIALES HOME******/
@media (min-width:600px){

}
.video-contenidos{  
  height: 40rem;
  width: 100%;
  padding: 4rem;
}
.video-container{
  margin-left: 1.2rem;
}

.react-player__preview{
  width: 100%;
    height: 100%;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    padding: 2rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

/***SORTEOS HOME***/
.sorteos-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.sorteos-fila{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin: 1rem;

}

.sorteos-img{
  width: 16em;
  height: 16em;
}
.sorteos-img-tickets{
  width: 16em;
  height: 16em;
  margin-top: 2rem;
  margin-left: 1rem
}
.price-user19{
  display: flex;
  justify-content: flex-start;
  font-size: 1.5rem;
  margin-left: 1rem;
}


@media (min-width:700px){
  .sorteos-container{

  flex-direction: row !important;
  height: 50rem;
}
.sorteos-img-tickets{
  width: 25em;
  height: 25em;
  margin-left: 1rem
}

}
/***CONTACTO****/
.leaflet-container {
  max-width: 100%;
  height: 40rem;
  margin-bottom: 5rem;
}
.google-container{
  width: 100%;
  height: 40rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
@media (min-width:700px){
  .leaflet-container{

    width: 32rem;
    height: 30rem;
  }
  .texto-contacto{
    margin-bottom: 10rem;
  }
  .google-container{
  margin-left: 1rem;
  }
}

.container-login-form{
  display: flex;
  flex-direction: column;

}
.contacto-home{
  display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
}
.datos-forms-c{
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}


 .boton-contacto{
   display: flex;
   align-items: center;
 }
 @media (min-width:700px){
  .contacto-home{
    display: flex;
    justify-content: space-evenly;
      flex-direction: row;
      align-items: flex-start;
  }

 }

 /** SOCIOS HOME****/
 .Socios-container{
  background-color:  #f0ad4e;
  margin: 2rem;
 }
 .rwt__tab {
  padding: 0.5rem !important;
 }
 .socios-home{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 .img-socios{
   width: 25rem;
   height: 20rem;
 }
 @media (min-width:700px){
  .socios-home{

    flex-direction: row;
  }
  .Socios-container{
   max-width: 50%;
   }
}

/**CAROUSEL SOCIOS**/
.img-carousel-socios{
  width: 100%;
  /* height: 15rem; */
}
.img-carousel-container{
  margin-bottom: 4rem
}
.img-carousel-c{
  background-color: white;
}
@media (min-width:700px){
  .img-carousel-socios{
    width: 100%;
    height: 25rem;
  }
  .img-carousel-container{
    margin-left: 16rem;
    margin-right: 16rem
  }
}
.img-carousel-c{
  background-color: white;
}

/*****BONOS****/
.bonos-container{
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.bonos-img{
  width: 16em;
  height: 16em;
}
@media (min-width:700px){
  .bonos-img{
    width: 20em;
    height: 20em;
  }
}
/***LOGIN****/
.error{
  margin: 1.5rem;
  color: white;
  font-size: 1.5rem;
  background-color: black;
  padding: 0.6rem;
  box-shadow: 5px 5px 5px black;
}
.container-contraseña{
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-login{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* align-items: center; */
}
.datos-forms-1{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;


}
.fecha-nacimiento {
    display: flex;
    justify-content: center;
    /* flex-direction: row; */
    margin-left: 0.5rem;
}

.input-fecha{
    width: 10.6rem;
    height: 4rem;
    padding: 20px;
    margin-right: 0.5rem;
    border: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: 'Roboto', sans-serif;
    font-display: swap;

}
.doc-container{
  display: flex;
  flex-direction: row;
}
.sexo-container{
  display: flex;
  flex-direction: row;
  margin-left: 2rem;
  

}
.sexo-input{
  margin-right: 1rem;
  margin-left: 1rem;

}
.centrar-botones-r{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.centrar-botones-r2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.container-recuperar-c{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width:860px){ 
.input-fecha{
  width: 13rem;
  height: 4rem;
  padding: 20px;
  margin-right: 0.5rem;
  border: #fff;
  border-radius: 10px;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif;
  font-display: swap;

}
.fecha-nacimiento {
  display: flex;
  justify-content: flex-start;
  /* flex-direction: row; */
  margin-left: 0.5rem;
}
.comentarios{
  padding: 25px 25px 25px 25px;
  border: white;
  border-radius: 20px;
  margin-bottom: 20px;
  width: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;

}
}


/*****JUEGO GRATIS Y PAGO******/
.banner-maquina{
  width: 100%;
  height: auto;
}
.banner-maquina-2{
  width: 100%;
  height: auto;
 
}
.banner-maquina-1{
  width: 100%;
  height: auto;
  
  
}
@media (max-width:760px){ 
  .banner-maquina-2{
    width: 100%;
    height: auto;
    
  }
  .banner-maquina-1{
    width: 100%;
    height: auto;
   display: none;
  }
  
}
@media (min-width:760px){ 
  .banner-maquina-1{
    width: 100%;
    height: auto;
    
  }
  .banner-maquina-2{
    width: 100%;
    height: auto;
   display: none;
  }
  
}
.img-android{
  height: 3rem;
  width: 2rem;
  margin-right: 0.5rem;
}
.img-iphone{
  height: 3rem;
  width: 2.5rem;
  margin-right: 0.5rem;

}
.descargas-container{
  background-color: #FFBA00

}
.botones-2{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.reproductor{
  width: 100%;
  height: 100%;
}
.a-jugar{
  display: flex;
  flex-direction: row;
  margin-left: 3rem;
  align-items: center;
}
.ganas-container-tutorial{
  background-color: rgb(214, 205, 205);
}
.test-ludo{
  background-color: #f0ad4e;
    margin-top: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;

}
.mgratis-1{
  margin-bottom: 2rem;
}
.estrellas-img{
  max-width: 35rem;
}
.estrellas{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  padding-top: 1rem;
}

/****CANJEAR PRODUCTOS*****/
.img-producto{
  max-width: 18rem;
  max-height: 15rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.container-img{
  display: flex;
  justify-content: center;
  background-color: white;
  width: 15rem;
  height:  24rem;
  margin-left: 2.5rem;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  border-radius: 0.5rem;
  margin-bottom: 1rem
}
.price-user{
  display: flex;
  justify-content: right;
  margin-top: 2rem;
  font-size: 1.5rem;
}
.price-user1{
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-top: 2rem;
  font-size: 1.5rem;
  height: 5rem;

}

.card-text{
  font-size: 1.5rem !important;
}
.row-cols-2>* {

max-width:100% !important;
}
.row {
margin-right:0 !important; 

}
.container-descripcion{
  display: flex;
  flex-direction: row;
}
.container-card{
  display: flex;
  flex-direction: column;
}
.container-card12{
  display: flex;
  flex-direction: column;
  margin-left: 0 !important;
}
@media (min-width:860px){ 
  .container-card{
    display: flex;
    flex-direction: row;
  }
  .container-card12{
    display: flex;
    flex-direction: row;
 
}
}


/***MI CUENTA*/
.container-cuenta{
  background-color: white;
  margin: 2rem;
}
.container-cuenta13{
  background-color: white;
  margin: 2rem;
}
.container-puntos{
  background-color: rgb(235, 235, 131);
  margin: 2rem;
  display: flex;
  align-items: center;
  border-radius: 1rem;
  
}
.td{
  font-size: 1.5rem;
}
@media (min-width:760px){
 
.container-micuenta{
display: flex;
justify-content: left;
}
.container-cuenta{
width: 50rem;
}
.container-cuenta13{
  width: 40rem;
}
.colum-cuenta{
  width: 10rem;
}
.container-puntos{
  width: 30rem;
}
.cuadro-fidelidad{
  margin-top: 4rem;
}
}

/**PRODUCTOSID***/
.container-pid{
  display: flex;
  justify-content: center;
  align-items: center;
}
.caracteristicas-t{
  padding: 2rem;
}
.product-card{
  background-color: white !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  margin: 2rem;
  padding: 1.5rem;
  padding-top: 1rem;
  border-radius: 1rem
}
.column-cards-id{
  display: flex;
  justify-content: center;
  align-items: center;

}
.cards-id-img {
  max-width: 25em;
  max-height: 35em;
}
.titulo-card{
  display: contents;
 
}
.puntos-details{
  font-size: 1.5rem;
}
.botones-idproducto{
  display:flex;
  align-items:center;
  flex-direction: column;
}
@media (min-width:700px){
  .caracteristicas-t{
    padding: 2rem;
    padding-left: 10rem;
    padding-right: 10rem;
  }

.product-card {
  background-color: white !important;
  display: flex;
  justify-content: left; 
  flex-direction: row;
  align-items: center;
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  margin: 2rem;
  padding: 3rem;
  border-radius: 1rem;
}
.fila-card{
  display: flex !important;
  align-items: center;
  flex-direction: column;
  margin-right: 5rem;
  margin-left: 5rem;
}
.container-pid {
  display: flex;
  padding: 10rem;
  padding-top: 5rem;
  justify-content: center;
  align-items: center;
}
}
/** PEDIDOS***/
.lista-con-backg{
  background-color:#f0ad4e ;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: row;
  margin: 1rem;
  padding: 1rem;
}
.orden{
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: column;
}

/****COMPRA****/
.container-compra{
  background-color:#f0ad4e ;
  margin: 2rem;
  padding: 1rem;
  width: 32rem;
  
}
.montos{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.bonos-qty{
  display: inline-flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
  margin-bottom: 2rem;
  
}
.qty-b{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
    border-radius: 2rem;
    border: black;
    height: 3rem;
    width: 15rem;
    margin-top: 2rem;
    margin-left: 2rem;

}
.container-pagos{
display: flex;
flex-direction: column;
align-items: initial;
justify-content: center;
background-color: white;
margin-top: 2rem;

}
.img-personal{
  max-width: 15rem;
  max-height: 4.4rem;
  margin-left: 5.5rem;
  margin-right: 2rem;

}
.img-tigo{
  max-width: 15rem;
  max-height: 5rem;
  margin-left: 4rem;


}

.l-pagos{
  display: flex;
    height: 5rem;
    flex-direction: row;
    align-items: center;
}
.input-p{
  margin-right: 2rem;
  margin-left: 3rem;
}
.container-gral-c{
  display: flex;
  justify-content: center;
}
@media (min-width:550px){
  .container-compra{
    width: 50rem;
    height: 60rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

/****CAROUSEL ***/
.slick-dots li button {
  font-family: -webkit-body;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  
  border-color: white !important;
  border-radius: 100%;
}
.slick-dots {
  position: initial !important;
}
.slick-dots li.slick-active button:before {
  
  color: white !important;
  background-color: white !important;
  border-radius: 100% !important;
}
.slick-dots li button:before {
  color: transparent !important;
  border: solid white !important;
  border-radius: 100%;
}

/***NOT FOUND***/
.link-notfound{
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
  margin-right: 5rem;
}
.container-notf{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 2rem;
  margin-top: 15rem;
}
.img-notfound{
  width: 20rem;
  height: auto;
}

/**OFERTAS PAG PROXIM DINAMICA*/
.oferta-container-4{
display:flex ;
align-items: center;
flex-direction: column;
text-align: justify;
}
.oferta-4{
  display:flex ;
  align-items: center;
  flex-direction: column;
  text-align: justify;
  padding: 1rem;
  }
  .img-ofert-4{
    max-width: 30rem !important;
    height: 30rem;
  }

  @media (min-width:700px){
    .oferta-container-4{
      display:flex ;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-top: 10rem;
      }
      .oferta-4{
        display:flex ;
        align-items: center;
        flex-direction: column;
        text-align: justify;
        padding: 2rem;
        }
        .img-ofert-4{
          max-width: 40rem !important;
          height: 40rem;
          margin-left: 2rem;
        }
  }

  /*********SPINNER*********/
.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color:orange;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
          animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg); }
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg); }
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg); }
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg); }
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg); }
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg); }
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg); }
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg); }
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg); }
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); }
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); }
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; }
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; }
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; }
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; }
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; }
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; }
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; }
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s; }
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s; }
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s; }

@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  } 40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color:#f0ad4e !important;

}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}


.modal-header {
  display: flex;
  justify-content: flex-end;
}

/* .modal{
  
} */
.close-modal{
  display: flex;
  font-size: 30px;
  color:white;
  margin-left: 85%;
  cursor: pointer;
  width:5rem; 
  background: none;
  border: none;
}

button {
  font-size: .9rem;
  font-weight: 700;
  border: none;
  border-radius: 3px;
  padding: .3rem 1rem;
  margin-left: .5rem;
}

.button-default {
  background: #247BA0;
  color: #fff;
}

.container-descripcion-notas{
  display: flex;
  flex-direction: column;
  background-color:#f0ad4e ;
  margin: 2rem;
  padding: 1rem;
  
}
.notas-id{
  background-color:#f0ad4e ;
  padding: 1rem;
  margin: 1rem;
}
@media (min-width:700px){ 
  .notas-id{
    margin: 2rem;
  }
}
.img-notas{
  width: 100%;
  height: 10rem;

}
.link-name-notas{
  align-items: center;
}
.container-card-notas{
  display: flex;
  flex-direction: column;
  margin-left: 0 !important;
}
.link-notas{
  text-decoration: underline;
  color: white;
}
@media (min-width:860px){ 
  .container-card-notas{
    display: flex;
    flex-direction: row;
  }
  }

    /*****NOTICIAS****/
    .img-noticias-22 {
      width: 100%;
      height: 100%;
    }
